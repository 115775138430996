import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
// import FileBase from 'react-file-base64';
import { useHistory } from 'react-router-dom';
// import ChipInput from 'material-ui-chip-input';

import { createPost, updatePost } from '../../actions/posts';
import useStyles from './styles';

const Form = ({ currentId, setCurrentId, mbForm }) => {
  const [postData, setPostData] = useState({ title: '', message: '', phone1: '', phone2: '', link1: '', email1: '', priv1: '', tags: [], selectedFile: '' });
  const post = useSelector((state) => (currentId ? state.posts.posts.find((message) => message._id === currentId) : null));
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('profile'));
  const history = useHistory();
  let file1 = null;

  const clear = () => {
    file1 = null;
    setCurrentId(0);
    setPostData({ title: '', message: '', phone1: '', phone2: '', link1: '', email1: '', priv1: '', tags: [], selectedFile: '' });
    if (mbForm === 1) {
      history.push(`/posts/${currentId}`);
    }
  };

  useEffect(() => {
    if (mbForm !== 1) {
      if (!post?.title) clear();
    }
    if (post) setPostData(post);
  }, [post]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('handleSubmit-1:', postData);
    postData.tags = postData.tags.map((tag) => tag.toLowerCase());
    // const lowerTags = postData.tags.map((tag) => tag.toLowerCase());
    // console.log('lowerTags-1:', lowerTags);
    // setPostData.tags = { ...lowerTags };
    console.log('handleSubmit-2:', postData);

    if (currentId === 0) {
      if (postData.title === '') {
        postData.title = 'No Title';
      }

      // const lowerTags = postData.tags.map((tag) => tag.toLowerCase());
      // console.log(lowerTags);

      // postData.tags = { ...lowerTags };
      // if (postData.tags.length === 0) {
      //   postData.tags = postData.title;
      // }
      dispatch(createPost({ ...postData, name: user?.result?.name }, history));
      clear();
    } else {
      dispatch(updatePost(currentId, { ...postData, name: user?.result?.name }));
      clear();
    }
  };

  if (!user?.result?.name) {
    return (
      <Paper className={classes.paper} elevation={6}>
        <Typography variant="h6" align="center">
          Please - SIGN IN - to create your own myMemiii and like other myMemiiis.
        </Typography>
      </Paper>
    );
  }

  // const handleAddChip = (tag) => {
  //   setPostData({ ...postData, tags: [...postData.tags, tag] });
  // };

  // const handleDeleteChip = (chipToDelete) => {
  //   setPostData({ ...postData, tags: postData.tags.filter((tag) => tag !== chipToDelete) });
  // };

  //! MB eslint deaktiviert
  /* eslint-disable */

  function calcImageSize(image) {
    let y = 1;
    if (image.endsWith('==')) {
      y = 2;
    }
    const xSize = (image.length * (3 / 4)) - y;
    return Math.round(xSize / 1024);
  }

  function mbCalcImageSize(s) {
//    return encodeURI(s).split(/%..|./).length - 1;  
    const img1 = s.toString;
    console.log(img1);
    const a1 = img1.indexOf(',');
    console.log(a1);
    const buffer = Buffer.from(img1.substring(img1.indexOf(',') + 1),'base64');

    console.log("Byte length: " + buffer.length);
    console.log("MB: " + buffer.length / 1e+6);}

  async function reduceImageFileSize(base64Str, MAX_WIDTH = 450, MAX_HEIGHT = 450) {
    const resizedBase64 = await new Promise((resolve) => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let { width } = img;
        let { height } = img;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); // this will return base64 image results after resize
      };
    });
    return resizedBase64;
  }

  async function imageToBase64(file) {
    const resultBase64 = await new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.onerror = (error) => {
        console.log(error);
        alert('An Error occurred please try again, File might be corrupt');
      };
      fileReader.readAsDataURL(file);
    });
    return resultBase64;
  }

  async function mbProcessImage(file, minImageSize = 300) {
    const res1 = file;
    if (res1) {
      const oldSize = mbCalcImageSize(res1);
      console.log('old size=> ', oldSize, 'KB');
      if (oldSize > minImageSize) {
        const resized = await reduceImageFileSize(res1);
        const newSize = mbCalcImageSize(resized);
        console.log('new size=> ', newSize, 'KB');
        console.log('old size=> ', oldSize, 'KB');
        return resized;
      }
      console.log('image already small enough');
      return res1;
    }
    console.log('return err');
    return null;
  }

  async function processImage(file, minImageSize = 300) {
    const res = await imageToBase64(file);
    if (res1) {
        const oldSize = calcImageSize(res1);
        if (oldSize > minImageSize) {
            const resized = await reduceImageFileSize(res1);
            const newSize = calcImageSize(resized)
            console.log('new_size=> ', newSize, 'KB');
            console.log('old_size=> ', oldSize, 'KB');
            return resized;
        } else {
            console.log('image already small enough')
            return res1;
        }

    } else {
        console.log('return err')
        return null;
    }
}


  /* - NOTE: USE THIS JUST TO GET PROCESSED RESULTS -*/
  async function mbF1(base64) {
    console.log(base64);
    const image = await processImage(base64);
    console.log(image);
  }

  async function previewImage() {
    const file = document.getElementById('file');
    const image = await processImage(file?.files[0]);
    console.log(image);
  }

  /**
 * Resize a base 64 Image
 * @param {String} base64Str - The base64 string (must include MIME type)
 * @param {Number} MAX_WIDTH - The width of the image in pixels
 * @param {Number} MAX_HEIGHT - The height of the image in pixels
 */
async function reduce_image_file_size(base64Str, MAX_WIDTH = 450, MAX_HEIGHT = 450) {
  let resized_base64 = await new Promise((resolve) => {
      let img = new Image()
      img.src = base64Str
      img.onload = () => {
          let canvas = document.createElement('canvas')
          let width = img.width
          let height = img.height

          if (width > height) {
              if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width
                  width = MAX_WIDTH
              }
          } else {
              if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height
                  height = MAX_HEIGHT
              }
          }
          canvas.width = width
          canvas.height = height
          let ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          resolve(canvas.toDataURL()) // this will return base64 image results after resize
      }
  });
  return resized_base64;
}


async function image_to_base64(file) {
  let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.onerror = (error) => {
          console.log(error)
          alert('An Error occurred please try again, File might be corrupt');
      };
      fileReader.readAsDataURL(file);
  });
  return result_base64;
}

async function process_image(file, min_image_size = 300) {
  const res = await image_to_base64(file);
  if (res) {
      const old_size = calc_image_size(res);
      if (old_size > min_image_size) {
          const resized = await reduce_image_file_size(res);
          const new_size = calc_image_size(resized)
          console.log('new_size=> ', new_size, 'KB');
          console.log('old_size=> ', old_size, 'KB');
          return resized;
      } else {
          console.log('image already small enough')
          return res;
      }

  } else {
      console.log('return err')
      return null;
  }
}

/*- NOTE: USE THIS JUST TO GET PROCESSED RESULTS -*/
async function preview_image(e, name) {
  file1 = document.getElementById('file');
  if (file1 !== null) {
    console.log ('is not null');
    console.log (file1.files[0].toString());
  } else {
    console.log ('is null');
  }
    const image = await process_image(file1.files[0]);
    console.log('Image: ',image);
    ({ image }) => setPostData({ ...postData, selectedFile: image });
    postData.selectedFile = image;
}

/*- NOTE: USE THIS TO PREVIEW IMAGE IN HTML -*/
// async function preview_image() {
//     const file = document.getElementById('file');
//     const res = await image_to_base64(file.files[0])
//     if (res) {
//         document.getElementById("old").src = res;

//         const olds = calc_image_size(res)
//         console.log('Old ize => ', olds, 'KB')

//         const resized = await reduce_image_file_size(res);
//         const news = calc_image_size(resized)
//         console.log('new size => ', news, 'KB')
//         document.getElementById("new").src = resized;
//     } else {
//         console.log('return err')
//     }
// }


function calc_image_size(image) {
  let y = 1;
  if (image.endsWith('==')) {
      y = 2
  }
  const x_size = (image.length * (3 / 4)) - y
  return Math.round(x_size / 1024)
}

  //!MB eslint aktiviert
  /* eslint-enable */

  return (
    <Paper className={classes.paper} elevation={6}>
      <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
        <Typography variant="h6">{currentId ? `Editing "${post?.title}"` : 'Creating a myMemiii'}</Typography>
        <TextField required="true" name="title" variant="outlined" label="Title" fullWidth value={postData.title} onChange={(e) => setPostData({ ...postData, title: e.target.value })} />
        <TextField name="message" variant="outlined" label="Message" fullWidth multiline rows={4} value={postData.message} onChange={(e) => setPostData({ ...postData, message: e.target.value })} />
        <TextField name="phone1" variant="outlined" label="Phone 1 with +49..." fullWidth value={postData.phone1} onChange={(e) => setPostData({ ...postData, phone1: e.target.value })} />
        <TextField name="phone2" variant="outlined" label="Phone 2 with +49..." fullWidth value={postData.phone2} onChange={(e) => setPostData({ ...postData, phone2: e.target.value })} />
        <TextField name="link1" variant="outlined" label="Link incl. http:// or https://" fullWidth value={postData.link1} onChange={(e) => setPostData({ ...postData, link1: e.target.value })} />
        <TextField name="email1" variant="outlined" label="Email" fullWidth value={postData.email1} onChange={(e) => setPostData({ ...postData, email1: e.target.value })} />
        <TextField name="priv1" variant="outlined" label="Write NO if public" fullWidth value={postData.priv1} onChange={(e) => setPostData({ ...postData, priv1: e.target.value })} />
        <div style={{ padding: '5px 0', width: '94%' }}>
          <TextField
            name="tags"
            variant="outlined"
            label="Tags (comma separated)"
            fullWidth
            value={postData.tags}
            onChange={(e) => setPostData({ ...postData, tags: e.target.value.split(',') })}
          />
        </div>
        {/* <div style={{ padding: '5px 0', width: '94%' }}>
        <ChipInput
            name="tags"
            variant="outlined"
            label="Tags"
            fullWidth
            value={postData.tags}
            onAdd={(chip) => handleAddChip(chip)}
            onDelete={(chip) => handleDeleteChip(chip)}
          />
        </div> */}
        {/* <div className={classes.fileInput}><FileBase type="file" multiple={false} onDone={({ base64 }) => setPostData({ ...postData, selectedFile: base64 })} /></div> */}
        <div className={classes.fileInput}>
          <Typography variant="body2">Please use a very small picture ...</Typography>
          <input id="file" accept=".jpg, .png, .jpeg" type="file" onChange={(e) => preview_image(e, 'markus')} />
        </div>
        <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" fullWidth>Submit</Button>
        <Button variant="contained" color="secondary" size="small" onClick={clear} fullWidth>Clear</Button>
      </form>
    </Paper>
  );
};

export default Form;
